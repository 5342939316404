.skills-section {
  padding: 10.5rem 0;
  background-color: #f9f9f9;
}

.container {
  text-align: left;
  margin: -24px auto;
  max-width: 74rem;
}

.title h1 {
  color: #147efb;
  font-size: 1.18rem;
  font-weight: 700;
  margin-bottom: 0.69rem;
  text-transform: uppercase;
}

.container h3 {
  color: #2d2e32;
  font-family: Mulish, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 4.2rem;
}
@media (max-width: 768px) {
  .container h3 {
    text-align: center;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    margin-bottom: -2.8rem;
  }
}

@media (max-width: 768px) {
  .title h1 {
    text-align: center;
  }
}

.skills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 113px;
}

@media (max-width: 500px) {
  .skills {
    grid-template-columns: none;
    padding: 5.5rem 0;
    margin: 1rem;
  }
}

@media (max-width: 768px) {
  .skills-section {
    padding: 5.5rem 0;
  }
}
