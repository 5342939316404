nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin-right: 128px;
}

@media (max-width: 500px) {
  nav ul {
    margin-right: 32px;
  }
}

nav h1 {
  margin-left: 18px;
}

nav ul a {
  color: #2d2e32;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.4s;
  &:hover {
    color: #147efb;
  }
}

@media (max-width: 900px) {
  nav ul a {
    display: none;
  }
}

nav {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  position: fixed;
  justify-content: space-between;
  padding: 25px 40px 25px 50px;
  left: 0;
  top: 0;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  z-index: 200;
}

@media (max-width: 500px) {
  nav {
    padding: 10px 40px 10px 0px;
  }
}

.logo {
  color: #2d322d;
  cursor: pointer;
}

.mobile-menu {
  font-size: 1.4rem;
  cursor: pointer;
  display: none;

  &:hover {
    color: #147efb;
    transition: all 0.3s;
  }
}

@media (max-width: 900px) {
  .mobile-menu {
    display: flex;
  }
}

.mobile-nav {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 300;
}

.mobile-nav span {
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  right: 2rem;
  top: 1rem;
}
/* display: flex;
    flex-direction: column;
    font-size: 2.3rem;
    gap: 4rem;
    list-style: none; */
.mobile-nav ul {
  display: flex;
  flex-direction: column;
  font-size: 1.42rem;
  gap: 2.4rem;
  list-style: none;
}

.mobile-nav ul a {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
