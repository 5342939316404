.details {
  font-family: Poppins, sans-serif;
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  margin-bottom: 7.2px;
}

@media (max-width: 500px) {
  .details {
    gap: 1.2rem;
  }
}

.detail-box {
  width: 9rem;
  background-color: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 0.8rem;
}

@media (max-width: 500px) {
  .detail-box {
    min-width: 91px;
    width: auto;
    padding: 0.3rem;
  }
}

img {
  height: 25px;
}
