.about {
  padding: 10.5rem 0;
  background-color: #fff;
}

@media (max-width: 1020px) {
  .about {
    padding: 100rem 0;
  }
}

@media (max-width: 500px) {
  .about {
    padding: 6rem 0;
  }
}
.container {
  padding: 0 4rem;
  margin: 0 auto;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  max-width: 74rem;
}

@media (max-width: 500px) {
  .container {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.image .laptop-image {
  position: relative;
  width: 28.53rem;
  height: 23.36rem;
  border-radius: 1.18rem;
  bottom: 0px;
  right: 98px;
}

@media (max-width: 500px) {
  .image .laptop-image {
    width: 90%;
    height: auto;
    margin-left: 118px;
  }
}

.side-text h1 {
  color: #147efb;
  font-size: 1.18rem;
  font-weight: 700;
  margin-bottom: 0.69rem;
  text-transform: uppercase;
}

.side-text h4 {
  color: #2d2e32;
  font-family: Poppins, sans-serif;
  font-size: 1.74rem;
  line-height: 1.4;
  margin-bottom: 1.39rem;
}

.side-text p {
  color: #767676;
  font-family: Mulish, sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .side-text p {
    padding: 1rem;
  }
}

.side-text {
  text-align: justify;
  padding-right: 0.6rem;
}
@media (max-width: 768px) {
  .side-text {
    padding-top: 1rem;
    text-align: center;
  }
}

.button-40 {
  margin-top: 0.2rem;
  background-color: #111827;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "poppins", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.75rem 1.2rem;
  text-align: center;
  text-decoration: none #6b7280 solid;
  text-decoration-thickness: auto;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

.button-40:hover {
  background-color: #374151;
}

.button-40:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .button-40 {
    padding: 0.75rem 1.5rem;
  }
}

.button-40 a {
  color: #fff;
  text-decoration: none;
}

.button_icon {
  margin-left: 10px;
  margin-bottom: -5px;
}
