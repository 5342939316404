.container {
  text-align: left;
  margin: -24px auto;
  max-width: 74rem;
}

@media (max-width: 750px) {
  .container {
    text-align: center;
  }
}

.contact {
  padding: 10.5rem 0;
  background-color: #fff;
}

@media (max-width: 750px) {
  .contact {
    padding: 6rem 0;
  }
}

.contact h1 {
  color: #147efb;
  font-size: 1.18rem;
  font-weight: 700;
  margin-bottom: 0.69rem;
  text-transform: uppercase;
}

.container > h3 {
  color: #2d2e32;
  font-family: Mulish, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 4.2rem;
}
.icons {
  display: flex;
  flex-wrap: wrap;
  gap: 5.5rem;
  margin-top: 4.1rem;
}
@media (max-width: 750px) {
  .icons {
    justify-content: center;
    gap: 3rem;
  }
}

.icons span {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.6rem;
}

.icons i {
  color: #147efb;
  font-size: 2rem;
}

.icons .details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.iconbox {
  align-items: center;
  gap: 1.5rem;
  display: flex;
}

@media (max-width: 750px) {
  .iconbox {
    flex-direction: column;
  }
}

.details h3 {
  color: #2d2e32;
  font-size: 1.18rem;
}

.details p {
  color: #767676;
  cursor: pointer;
  font-size: 1.18rem;
  text-decoration: none;
}

.details a {
  text-decoration: none;
  font-size: 1.18rem;
  cursor: pointer;
  color: #767676;
}
